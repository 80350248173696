<template>
  <div id="app">
    <HeaderComponent />
    <div class="main">
      <Transition mode="out-in" name="page">
        <RouterView :key="$route.fullPath" />
      </Transition>
    </div>
    <FooterComponent />
    <ModalComponent />
  </div>
</template>

<script>
import FooterComponent from "components/FooterComponent.vue";
import HeaderComponent from "components/HeaderComponent.vue";

export default {
  name: "App",
  metaInfo() {
    const meta = this.$store.state.meta.title;
    return {
      titleTemplate: `%s | ${meta}`,
    };
  },
  components: {
    HeaderComponent,
    FooterComponent,
    ModalComponent: () => import("components/modals/index.vue"),
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
.main {
  min-height: 100vh;
}
</style>
