import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
    },
    {
      path: "/hero/:id?",
      name: "hero",
      component: () => import("./views/heroes/open/index.vue"),
    },
    {
      path: "/news",
      name: "news",
      component: () => import("./views/news/list/index.vue"),
    },
    {
      path: "/news/:id?",
      name: "news-item",
      component: () => import("./views/news/open/index.vue"),
    },
    {
      path: "/unity",
      name: "unity",
      component: () => import("./views/unity/index.vue"),
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
  ];
  return new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
}
