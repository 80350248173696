import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default function createStore(env) {
  return new Vuex.Store({
    state() {
      return {
        _env: env,
        _modals: [],
        user: null,
        meta: {
          title: "Герои Республики Дагестан",
        },
        home_page: {
          heroes: [],
        },
        heroes_open_page: {
          hero: {},
          heroes: [],
        },
        news_page: {
          news: [],
        },
        news_open_page: {
          news: [],
          news_item: {},
        },
        unity_page: {},
        wives_page: {},
        search_modal: {
          news: [],
          heroes: [],
        },
      };
    },
  });
}
